.login {

    display: grid;
    place-items: center;
    background-color: white;
    height: 100vh;


}

.login > button {
width: 300px;
background-color: #2e81f4;
color: #eff2f5;
font-weight: 800;

}

.login > button:hover {
    background-color: white;
    color: #2e81f4;
}

.login > div > img {
    object-fit: contain;
    height: 50px;
}

.login > div {
    display: flex;
    flex-direction: column;
}