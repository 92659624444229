.header {
    padding: 15px 20px;
    display: flex; 
    justify-content: space-between;
    position: sticky;
    background-color: white;
    z-index: 100;
    top: 0;
    box-shadow: 0px 5px 8px -9x rgba(0, 0, 0, 0.75);
}

.header__left {
    display: flex;
    justify-content: space-evenly;
}

.header__input {
    display: flex;
    align-items: center;
    background-color: #eff2f5;
    padding: 10px;
    margin-left: 10px;
    border-radius: 999px;

}
.header__left > img {
    height: 50px;
}

.header__input > input {
     border: none;
     background-color: transparent;
     outline-width: 0;
    }

.header__center {
    display: flex;
    flex: 1;
    justify-content: center;
}

.header__option > .MuiSvgIcon-root {
    /* Changes all icons to gray */
    color: gray;
}

.header__option:hover > .MuiSvgIcon-root {
    /* color on mouse hover */
    color: #2e81f4;
}

.header__option--active > .MuiSvgIcon-root {
    color: #2e81f4;

}

.header__option--active {
    border-bottom: 4px solid #2e81f4;
}

.header__option { 
    display: flex;
    align-items: center;
    padding: 0 30px;
    cursor: pointer;
}
.header__option:hover { 
    background-color: #eff2f5;
    border-radius: 10px;
    align-items: center;
    padding: 0 30px;
    border-bottom: none;
}
.header__info {
    /* This is the user icon and name */
    display: flex;
    align-items: center;
    
}.header__right {
    display: flex;
}