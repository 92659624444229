.sidebarRow {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}

.sidebarRow:hover {
    background-color: lightgray;
    border-radius: 10px;
}

.sidebarRow > h4 {
    margin-left: 20px;
    font-weight: 600;
}

.sidebarRow > .MuiSvgIcon-root {
    font-size: xx-large;
    color: #2e81f4;
}